/* 葡萄牙语 */
export default {
  emailRegister: 'Registrar Email',
  registerNotice:
    'Contas não registradas são automaticamente registradas e logadas',
  enterEmail: 'Por favor, insira seu endereço de email',
  enterCode: 'Por favor, insira seu código',
  getCode: 'Obter código',
  signIn: 'Entrar agora',
  signPropt: 'Ao fazer login, você concorda com a',
  policy: 'Política de Privacidade',
  agreement: 'Contrato de Usuário',
  emailRuleError: 'O formato do email está incorreto',
  regisgerAndEnjoy: `Registre-se para Aproveitar Grandes Shows`,

  starcard: `Benefícios do Membro Starcard`,
  more: `Mais`,
  describe: `O Cartão M é a prova de direitos do sistema de promoção Plano M. O Cartão M é dividido em Cartão M e Cartão M Pro. Tornar-se usuário do Cartão M pode ganhar recompensas.`,
  starReward: `Recompensas do Cartão M`,
  unlock: `Destravar`,
  text1: `· Recurso de convite: convites diretos para se tornarem M-Card podem ganhar 10% de recompensas.`,
  text2: `· Recompensa de promoção do investimento em direitos de autor: convidados diretos para investir em direitos de autor, você pode ganhar 10% da renda diária do convidado.`,
  text3: `· Recompensas do filme: Convidados diretos para se tornarem VIP do filme podem ganhar 20% de recompensa; Convidados diretos para comprar Drama Point podem ganhar 40% de recompensa.`,
  starClu: `Recompensas da Equipe M`,
  text4: `Consulte o atendimento ao cliente para saber mais benefícios.`,
  copyright: `DIREITOS AUTORAIS`,
  td1: `Retorno anualizado`,
  td2: `Valor do investimento inicial`,
  td3: `Concluído/Total`,
  warning: `*A receita diária irá flutuar de acordo com o efeito da promoção.`,
  schedule: `Progresso do crowdfunding`,
  buynow: `Compre Agora`,
  download: `Baixar`,
  go: `Ir`,
  android: `Andróide`,
  apple: `Loja de aplicativos`,
  Characteristic: `Característica`,
  Participation: `Valor da Participação`,
  method: `Método de pagamento`,
  Payamount: `Valor do pagamento`,
  jump: `Pagar`,
  name1: `Esquetes`,
  name2: `Vários tipos`,
  name3: `Membro do Cartão M`,
  name4: `Receita de promoção`,
  describe1: `Garantia da qualidade`,
  describe2: `Pense o que você pensa`,
  describe3: `Subsídio enorme`,
  describe4: `Boa linha lateral`,
  warning1: `O conteúdo não pode estar vazio`,
  success1: `Vinculação bem-sucedida`,
  ends: `Fim da contagem regressiva`,
  warning2: `Você ainda não fez login.`,

  experienceStar: `Cartão M`,
  ExclusiveStar: 'Cartão M Pro',
  noCard: 'Sem cartão M',

  aboutus: `Sobre nós`,
  understand: `Sobre nós`,
  Snapshort: `${window.name} equipe:`,
  Innovators: `Inovadores remodelando o`,
  industry: `indústria cinematográfica e televisiva`,
  paragraph1_1: `Na era digital em constante mudança, um grupo de desenvolvedores apaixonados por arte cinematográfica e televisiva está trabalhando para remodelar a experiência de visualização do público.`,
  paragraph1_2: `Nossa equipe, ${window.name}, Tem a inovação como seu núcleo e a tecnologia como seu impulsionador, e está liderando a nova tendência na indústria cinematográfica e televisiva.`,
  paragraph1_3: `${window.name} é um aplicativo móvel com foco em conteúdo de drama curto. Selecionamos cuidadosamente dramas curtos de alta qualidade para se adaptar ao estilo de vida acelerado do público moderno.`,
  paragraph1_4: `Nosso aplicativo permite que os usuários assistam aos dramas curtos mais recentes e populares a qualquer hora, em qualquer lugar, ao mesmo tempo em que fornece recomendações personalizadas para que cada usuário possa encontrar seu conteúdo favorito.`,
  paragraph2_1: `Nossa equipe é composta por um grupo de desenvolvedores experientes e tecnicamente proficientes, proficientes em produção de conteúdo de cinema e televisão, análise de big data, recomendação de inteligência artificial e outras tecnologias.`,
  paragraph2_2: `Isso nos permite produzir e atualizar conteúdo com eficiência, ao mesmo tempo em que fornecemos recomendações personalizadas para cada usuário.`,
  paragraph2_3: `Nossa visão é permitir que cada usuário encontre sua própria diversão e inspiração no drama Gravidade. Acreditamos que, por meio de nossos esforços, ${window.name} se tornará parte de sua vida, permitindo que você encontre um momento de paz e alegria em sua vida agitada.`,
  paragraph3_1: `Esteja você procurando entretenimento alegre ou uma história instigante, ${window.name} você está coberto.`,
  paragraph3_2: `Vamos explorar juntos as infinitas possibilidades da arte cinematográfica e televisiva.`,

  period: `Cartão M`,
  Premier: `Cartão M Pro`,
  Expiration: `Data de término:`,
  renew: `Renove imediatamente`,
  balance: `Saldo do cartão M`,
  Income: `Renda acumulada`,
  wallet: `Transferir para a carteira`,
  daily: `Tarefa diária`,
  nocompleted: `Não concluído hoje`,
  code: `Filme VIP`,
  Residue: `Código de resgate restante`,
  reward: `Renda de hoje`,
  type: `Tipo`,
  amount: `Quantidade`,
  time: `Hora`,
  none: `Não há mais conteúdo`,
  load: `Carregamento`,
  loadmore: `Carregue mais`,
  success2: `Transferido para a carteira com sucesso`,
  five: `Talento M5`,
  four: `Talento M4`,
  Samsung: `Talento M3`,
  two: `Talento M2`,
  one: `Talento M1`,
  recommendation: `Recompensas de convite`,
  rewards: `Recompensas`,
  taskRewards: `Recompensas de tarefas`,
  starRewards: `Recompensas de promoção de direitos autorais`,
  MovieRewards: `Recompensas da promoção do cartão M`,

  annualized: `Retorno anualizado`,
  Initial: `Valor do investimento inicial`,
  CompletedTotal: `Concluído/Total`,
  tishi: `*A receita diária irá flutuar de acordo com o efeito da promoção.`,
  Crowdfunding: `Progresso do financiamento coletivo:`,
  dividend: `Saldo de dividendos de financiamento coletivo`,
  Project: `Nome do Projeto`,
  Cumulative: `Renda acumulada`,
  Dividend: `Raro Diário de Retorno`,
  investment: `Valor do investimento`,
  name5: `DIREITOS AUTORAIS`,
  name6: `CONTROLADO`,
  title1: `Em andamento`,
  title2: `Terminado`,
  title3: `Histórico de Participação`,
  title4: `Detalhes da receita`,
  text5: `Tempo de participação`,
  text6: `Tempo de receita`,
  text7: `Renda acumulada`,
  text8: `Valor do desembolso`,

  remaining: `Tarefas restantes:`,
  Claim: `Obter prazo da tarefa:`,
  Submission: `Prazo de submissão:`,
  Completion: `Tempo de conclusão:`,
  Completed: `Concluído`,
  Complete: `Completa`,
  error: `Falha na revisão da tarefa, reenvie a imagem para revisão.`,
  warning3: `A imagem de conclusão da tarefa deve exibir a data do dia.`,
  sample: `Amostra`,
  Copywriting: `Lema da promoção`,
  Claim2: `Obter Tarefa`,
  SubmitTask: `Submeter tarefa`,
  Waiting: `Revendo`,
  Resubmit: `Reenviar`,
  resubmit: `,Por favor, reenvie`,
  Expand: `Expandir`,
  Recover: `Retornar`,
  Deadline: `Prazo:`,
  text9: `1. A imagem de conclusão da tarefa deve exibir o slogan da promoção.`,
  text10: `2.O conteúdo de texto na imagem de conclusão da tarefa deve exibir a data do dia.`,
  success3: `Baixado Bem-sucedido`,
  error2: `Falha ao baixar`,
  success4: `Cópia bem-sucedida`,
  warning4: `Carregue a imagem de conclusão da tarefa.`,
  success5: `Carregamento bem-sucedido`,

  emaillogin: `Conecte-se com e-mail`,
  warning5: `O usuário não registrado será automaticamente registrado e conectado`,
  text11: `Obter código`,
  Sign: `Conecte-se agora`,
  text12: `Ao fazer login, você concorda com o`,
  Privacy: `Política de privacidade`,
  Agreement: `Termo de Acordo do Usuário`,
  warning6: `Por favor, digite seu endereço de e-mail`,
  warning7: `Digite o endereço de e-mail correto`,
  warning8: `O código de verificação não pode estar vazio`,
  warning9: `Por favor, digite seu endereço de e-mail`,
  retrieve: `recuperar`,
  Payment: `Confirme o pagamento`,
  InformationFilling: `Preencha as informações`,
  BalanceCombinationPayment: `Pagamento misto`,
  AvailableBalance: `Saldo disponível:`,
  will: `Enviar para`,
  setCode: `o código de verificação`,
  BalanceDeduction: `Após a dedução do saldo, ainda precisa pagar`,
  StarClusterLevel: 'Nível de Equiupe M',
  PersonalPerformance: 'Desempenho pessoal',
  StarClusterPerformance: 'Desempenho de Equipe M',
  memberNumber: 'Número de Equipe M',
  NumberStar: 'Número do cartão M',
  NumberDirectly: 'Convidados diretos',
  activeMembers: 'Número de usuários ativos',

  title_1: `Conecte-se`,
  title_2: `Casa`,
  title_3: `Carteira`,
  title_4: `Cartão M`,
  title_5: `Direitos autorais`,
  title_6: `Comunidade`,
  title_7: `Sobre nós`,
  title_8: `Benefícios para a comunidade`,
  title_9: `Trocar de conta`,
  title_10: `Sair`,
  title_11: 'Trocar código de resgate',
  title_12: `Mercado de Negociação`,
  title_13: `Chat IM`,

  interests: `Benefícios do Cartão M`,
  log_fir: `Por favor, faça o login.`,
  name7: `Conteúdo Livre`,
  name8: `Tarefa diária`,
  name9: `Membro do Cartão M`,
  name10: `Mais caraterísticas`,
  describe5: `Ganhe um filme VIP grátis`,
  describe6: `Subsídio enorme`,
  describe7: `Compartilhar e convidar`,
  describe8: `Desbloqueie para outros benefícios`,

  Invite: `Compartilhar e convidar`,
  invitation: `Meu código de convite`,
  directly: `Convidados diretos`,
  indirect: `Convidados indiretos`,
  Copy_link: `Copiar link de convite`,
  Fill: `Preencha o código de convite`,
  inv_code: `Código Convidado: `,
  Replicating: `Cópia bem-sucedida`,
  error3: `Falha na cópia`,
  error4: `Falha na colagem:`,

  Apple_login: `Faça login com a Apple`,
  Google_login: `Faça login com Google`,
  Email_login: `Faça login com Email`,

  Commission: `Taxa de comissão:`,
  nolevel: `Sem nível de equipe M`,
  community: `Número de Equipe M`,
  Community1: `Promover diretamente o desempenho`,
  promoted: `Número de convidados hoje`,
  Promote: `Recompensa pela Promoção de Direitos Autorais`,
  balance2: `Saldo`,
  Withdraw: `Transferir para carteira`,
  Transfer: `Transferência`,
  Interpersonal: `Promoção da Equipe M`,
  mixpayTips: `Priorize o saldo da carteira para pagar`,
  noBalanceL: `O saldo da carteira é insuficiente`,

  days: `Dias`,
  Selected: `Selecionado`,
  Select: `Selecionar`,
  paid: `Quantidade`,
  gopay: `Pagar`,
  warning10: `Escolha o método de pagamento`,

  progress: `Pagamento em andamento`,
  cancel: `Cancelar`,
  confirm: `Confirmar`,
  Paid: `Pago`,
  warning11: `Pago com-sucesso`,

  Remaining: `Código de resgate restante:`,
  Exchange: `Lista de resgate`,
  Redemption: `Código de resgate`,
  Duration: `Duração`,
  Period2: `Prazo de resgate`,
  State: `Estado`,
  Function: `função`,
  nouse: `Não utilizado`,
  Used: `Usado`,
  Copy: `Copiar`,

  Wallet2: `Histórico de Saldo da Carteira`,
  Variable: `Quantidade(${window.currency})`,
  OrderID: `Identificação`,
  Balance3: `Retirar`,
  Balance5: `Transferência`,
  information: `Preencha as informações`,
  Amount4: `Quantidade retirada`,
  Balance6: `Valor da transferência`,
  Balance4: `Saldo:`,
  nomoney: `O saldo da carteira é insuficiente`,
  crowdfunding: `Direitos autorais de participação`,

  title_name: `Contrato do Usuário`,
  t1: `Este contrato será emitido e entrará em vigor em 15 de março de 2023, com a última revisão feita em 15 de março de 2023`,
  t2: `${window.region} ${window.company} Tecnologia Co., Ltd. é o proprietário registrado dos direitos autorais deste software e goza dos direitos autorais deste software de acordo com a lei. Este contrato de usuário (doravante denominado 'este contrato') é um contrato válido entre ${window.company} Tecnologia Co., Ltd. (doravante denominada"${window.company} Tecnologia", "us") e o usuário (também referido como "você") sobre a instalação e uso do produto de software cliente "Juyouli" (doravante denominado "este software"). Ao clicar para confirmar este contrato ou optar por aceitá-lo de qualquer outra forma, você indica que chegou a um acordo com ${window.company} Tecnologia e concordo em aceitar todas as seguintes disposições deste contrato. Este software é operado por ${window.company} Tecnologia e fornece serviços de vídeo para você, desfrutando de direitos, cumprir obrigações e assumir responsabilidades legais de acordo com a lei.`,
  t3: `Depois que este contrato for alterado, o software solicitará modificações na página do software. Depois que o contrato de uso modificado é exibido na página do anúncio, ele substitui efetivamente o contrato original. Você deve fazer login neste software a qualquer momento para verificar os protocolos mais recentes. Antes de utilizar os vários serviços prestados por este software, deve ler atentamente este contrato. Se você não concordar com a modificação deste contrato, Você pode cancelar voluntariamente os serviços fornecidos por este software. Caso contrário, Seu uso será considerado como sua aceitação deste Contrato e de todo o Conteúdo modificado a qualquer momento. `,
  t4: `1、Como coletamos e usamos suas informações pessoais`,
  t5: `Informações pessoais referem-se a vários tipos de informações registradas eletronicamente ou de outras maneiras que podem, individualmente ou em combinação com outras informações, identificar a identidade de uma pessoa física específica ou refletir as atividades de uma pessoa física específica.`,
  t6: `${window.company} A tecnologia e o proprietário registrado dos direitos autorais deste produto só coletarão e usarão suas informações pessoais para os fins, métodos, e escopo descrito nesta política ao fornecer serviços a você:`,
  t7: `1. Função do aplicativo 1: Torne-se um usuário registrado`,
  t8: `Ao se inscrever para se tornar um usuário registrado deste produto, você precisa fornecer as seguintes informações: Informações do seu dispositivo.`,
  t9: `As informações acima que você forneceu nos autorizam a continuar usando este serviço para você. Quando você cancelar sua conta, deixaremos de usar e excluiremos as informações acima.`,
  t10: `2. Função do aplicativo 2: Exibir logs de operação dentro do aplicativo`,
  t11: `Quando você usa nossos serviços, este produto registra seus registros de operação, incluindo registros de navegação e clique, adição de programas de TV, exibição de programas de TV e participação em atividades. As informações acima que você forneceu nos autorizam a continuar usando este serviço para você. Quando você cancelar sua conta, deixaremos de usar e excluiremos as informações acima.`,
  t12: `3. Função do aplicativo três: assinatura e pagamento`,
  t13: 'Quando você usa este produto, os registros gerados ao solicitar serviços de assinatura VIP e pagamento de episódios, incluindo registros de assinatura e registros de consumo, serão armazenados em nosso servidor. As informações acima são necessárias para a funcionalidade do produto. Se você não concordar com a gravação das informações acima, não poderemos fornecer serviços de compra de episódios.',
  t14: `4. Função do aplicativo quatro: serviços de segurança`,
  t15: `Para fornecer serviços seguros e estáveis, garantir a segurança de sua conta e propriedade e evitar logins falsos, a fim de usar as funções e serviços deste produto normalmente, Podemos registrar automaticamente certas informações quando você usa nosso produto ou serviço, incluindo: informações do dispositivo ou software: informações de configuração fornecidas pelo seu dispositivo móvel, navegador da web ou outros programas usados para acessar nossos serviços, seu endereço IP, informações de versão de software usadas por seu dispositivo móvel, código de identificação do dispositivo (IMEI, endereço MAC), informações do identificador exclusivo do dispositivo (ID do Android, OAID), e número de série do dispositivo. Se você não concordar com o registro das informações acima mencionadas, Você pode não conseguir concluir o processo de controle de risco e usar nosso produto. Também podemos registrar automaticamente se você instalou o Alipay APP. Se você não concordar conosco em registrar as informações acima, Seu uso normal deste produto não será afetado.`,
  t16: `5. Função do aplicativo 5: Habilitando permissões`,
  t17: `Prometemos não exceder o escopo ou a frequência de coleta de suas informações pessoais e nunca coletar ou usar informações que não sejam necessárias ou não tenham cenários de aplicação razoáveis para os serviços deste produto.`,
  t18: `Ao usar este produto, com a finalidade de fornecer serviços, este produto pode solicitar as seguintes permissões para dispositivos terminais em determinados cenários em que você usa o aplicativo. Obviamente, você pode se recusar a ativar as seguintes permissões ou desativar as seguintes permissões para este produto nas configurações do seu telefone. Desativar as seguintes permissões não afetará o uso de outras funções deste produto:`,
  t19: `Acessar telefone: Ao obter a permissão de status do telefone, registramos o código de identificação do dispositivo (ou seja, código IMEI ou IMSI) para processamento de anonimização, exibição de publicidade e verificação de segurança;`,
  t20: `Acesse o armazenamento local: Ao ativar as permissões de armazenamento local, baixe os episódios deste produto para o armazenamento do seu telefone;`,
  t21: `Acesse fotos: você pode selecionar diretamente fotos ou imagens do seu telefone para fazer upload ou fornecer provas ao se comunicar com o atendimento ao cliente;`,
  t22: `Acesse conteúdo de mídia e arquivos em seu dispositivo: cache para ler e escrever capas de episódios e imagens de eventos, melhorando a fluência no uso do aplicativo.`,
  t23: `Ativar dados sem fio: Você pode obter as funções de rede exigidas por este produto conectando-se a redes sem fio ou celulares;`,
  t24: `Acesse a lista de programas de instalação: Ao obter sua lista de programas de instalação, podemos confirmar se você instalou os produtos que promovemos, para que possamos distribuir prontamente as recompensas correspondentes para você.`,
  t25: `Ligue o microfone e o reconhecimento de voz: Você pode controlar a função de leitura de voz e a função de controle de voz deste produto por meio de voz;`,
  t26: `Usar a área de transferência: você pode copiar e colar números de telefone do atendimento ao cliente ou optar por não usar permissões da área de transferência.`,
  t27: `Obtenha permissões de rede do dispositivo com a finalidade de usar dicionários, enciclopédias e leitura de voz;`,
  t28: `Obtenha uma localização aproximada: ao navegar em determinados anúncios, obtenha permissão de localização aproximada;`,
  t29: `Acesse o local de pesquisa: Ao navegar em determinados anúncios, ele obterá permissão de local de pesquisa;`,
  t30: `Usando uma câmera: quando os usuários fornecem feedback, eles podem obter permissão para usar a câmera.`,
  t31: `2、 Como usamos bolinhos e tecnologias semelhantes`,
  t32: `Bolinhos e tecnologias semelhantes são comumente usados na Internet. Para garantir o funcionamento normal desta plataforma e fornecer a você uma experiência de acesso mais fácil, podemos armazenar pequenos arquivos de dados chamados Bolinhos em seu dispositivo móvel. Os bolinhos geralmente contêm identificadores, nomes de sites e alguns números e caracteres. Com a ajuda de bolinhos, Registraremos suas informações de login e informações de operação neste produto, para que possamos armazenar seus hábitos de uso e ajudar a determinar o status de segurança de sua conta, proporcionando uma experiência de usuário e serviços mais descontraídos.`,
  t33: `Não usaremos cookies para nenhuma finalidade diferente das declaradas nesta política. Você pode gerenciar ou desativar bolinhos de acordo com suas preferências. Para obter mais informações, consulte sobre bolinhos. Org. Se os bolinhos forem desativados, você pode não conseguir desfrutar da melhor experiência de serviço, e nosso conteúdo recomendado também pode ser menos relevante para você.`,
  t34: `3、 Como compartilhamos, transferimos e divulgamos publicamente suas informações pessoais`,
  t35: `（1） Compartilhamento`,
  t36: `Não compartilharemos suas informações pessoais com nenhuma empresa, organização ou indivíduo que não seja a ${window.company} Tecnologia e o proprietário dos direitos autorais registrados e empresas afiliadas deste produto, exceto nas seguintes situações:`,
  t37: `1. Compartilhamento com consentimento explícito: Depois de obter seu consentimento explícito, compartilharemos suas informações pessoais com outras partes.`,
  t38: `2. Podemos compartilhar suas informações pessoais com o público de acordo com leis e regulamentos ou regulamentos obrigatórios de autoridades reguladoras governamentais.`,
  t39: `3. Compartilhamento com parceiros autorizados: Alguns de nossos serviços serão fornecidos por parceiros autorizados exclusivamente para os fins declarados nesta política. Podemos compartilhar algumas de suas informações pessoais com nossos parceiros para fornecer um melhor atendimento ao cliente e experiência do usuário. Compartilharemos suas informações pessoais apenas para fins legítimos, legítimos, necessários, específicos e claros, e compartilhar apenas informações pessoais necessárias para a prestação de serviços. Nossos parceiros não têm o direito de usar as informações pessoais compartilhadas para qualquer outra finalidade.`,
  t40: `Atualmente, nossos parceiros autorizados incluem os seguintes tipos:`,
  t41: `1) Fornecedores, prestadores de serviços, agentes terceirizados e outros parceiros. Enviamos informações para fornecedores, prestadores de serviços, agentes terceirizados e outros parceiros em todo o mundo que apoiam nossos negócios, incluindo o fornecimento de serviços de infraestrutura técnica, analisando o uso de nossos serviços, medindo sua eficácia, fornecendo serviços ao cliente, facilitando pagamentos, ou realização de pesquisas e pesquisas acadêmicas. Com o seu consentimento prévio explícito, podemos compartilhar seus registros de consumo, registros de assinatura, registros de recarga, registros de pedidos, registros de visualização excluídos e registros de consumo de atividade de consumo com agentes terceirizados após a adoção de métodos de criptografia e anonimização. Se você não concordar com o compartilhamento das informações acima, isso não afetará o uso normal deste produto.`,
  t42: `2) Provedores de serviços de dados de serviços de análise: Para fornecer melhores serviços, cooperamos com provedores de serviços de dados terceirizados, incluindo Youmeng+, para prever suas preferências de recursos. Para garantir a segurança de seus dados, essa previsão é fornecida na forma de pontuações exponenciais e a análise de dados é realizada sob estritas medidas de segurança de identificação, para não divulgar suas informações pessoais.`,
  t43: `3) Parceiros autorizados para serviços de publicidade e análise. A menos que obtenhamos sua permissão, não compartilharemos suas informações de identificação pessoal (referindo-se a informações que podem identificar sua identidade, através do qual você pode ser contatado ou identificado) com parceiros que fornecem serviços de publicidade e análise. Mas podemos compartilhar suas informações pessoais com parceiros autorizados em serviços de publicidade e análise após a anonimização, incluindo: informações de identificação de rede (endereço IP), número da versão do software, nome do pacote de software, lista de instalação do software, informações de conexão do dispositivo (operadora de telecomunicações, ambiente de rede), informações de atributos do dispositivo (fabricante do dispositivo, modelo do dispositivo, versão do sistema operacional, IMEI, OAID, IMSI, sensor, número de série do dispositivo), para ajudá-los a melhorar a taxa de alcance efetivo de anúncios sem reconhecer seu indivíduo.`,
  t44: `As permissões necessárias para serviços de acesso ao SDK de terceiros. Nossos produtos e serviços podem incluir produtos e serviços de terceiros, bem como links para sites de terceiros. Quando você usa esses produtos ou serviços, Você também pode coletar suas informações. Depois que o usuário concordar em autorizar, as informações pessoais serão enviadas a terceiros. Quando a identidade do terceiro muda, O consentimento do usuário será obtido novamente por meio de uma janela pop-up e compartilharemos informações pessoais com terceiros.`,
  t45: `（2） Transferência`,
  t46: `Não transferiremos suas informações pessoais para nenhuma empresa, organização ou indivíduo, exceto nas seguintes circunstâncias:`,
  t47: `1. Transferência com consentimento explícito: Depois de obter seu consentimento explícito, transferiremos suas informações pessoais para outras partes;`,
  t48: `2. Quando se trata de fusões, aquisições ou liquidação de falência, se envolver a transferência de informações pessoais, Exigiremos que a nova empresa ou organização que detém suas informações pessoais continue vinculada a esta Política de Privacidade. Caso contrário, Exigiremos que a empresa ou organização busque sua autorização e consentimento novamente.`,
  t49: `（3） Divulgação pública`,
  t50: `Só divulgaremos publicamente suas informações pessoais nas seguintes circunstâncias:`,
  t51: `1. Depois de obter seu consentimento explícito;`,
  t52: `2. Divulgação legal: podemos divulgar publicamente suas informações pessoais em requisitos legais, processuais, contenciosos ou obrigatórios de autoridades governamentais.`,
  t53: `（4） Os tipos de informações pessoais que podem estar envolvidas no compartilhamento externo incluem códigos de identificação do dispositivo e informações de versão do produto; Os tipos de informações pessoais que podem estar envolvidas na divulgação pública incluem assistir séries de TV e duração da visualização.`,
  t54: `Estamos cientes das responsabilidades legais correspondentes para compartilhar, transferir e divulgar publicamente informações pessoais ao público e tomaremos medidas razoáveis para garantir a segurança das informações pessoais.`,
  t55: `4、 Como protegemos e salvamos suas informações pessoais`,
  t56: `（1） Medidas técnicas de proteção`,
  t57: `Atribuímos grande importância à segurança de suas informações pessoais e nos esforçaremos para tomar várias medidas de segurança razoáveis que estejam em conformidade com os padrões do setor para proteger suas informações contra vazamento, dano ou perda, incluindo, mas não se limitando a SSL, armazenamento criptografado de informações de privacidade e controle de acesso em data centers. Usaremos tecnologia de criptografia para aumentar a segurança das informações pessoais; Usaremos mecanismos de proteção confiáveis para evitar ataques maliciosos a informações pessoais; Implantaremos mecanismos de controle de acesso para garantir que apenas pessoal autorizado possa acessar informações pessoais.`,
  t58: `（2） Sistema de gerenciamento de segurança`,
  t59: `Temos um sistema de gerenciamento de segurança de dados líder do setor centrado no ciclo de vida dos dados, que aumenta a segurança de todo o sistema em várias dimensões, como construção organizacional, design do sistema, gerenciamento de pessoal, tecnologia de produtos e avaliação de impacto na segurança da informação pessoal.`,
  t60: `Também implementamos medidas rígidas de gerenciamento para funcionários ou pessoal terceirizado que possam entrar em contato com suas informações, incluindo, mas não se limitando a, implementar diferentes controles de permissão com base em seus cargos, assinar acordos de confidencialidade com eles e monitorar suas operações.`,
  t61: `（3） Proteção da conta`,
  t62: `Sua conta tem funções de proteção de segurança, mantenha suas informações de conta e senha seguras. Se você achar que sua conta foi roubada ou suas informações pessoais vazaram, entre em contato conosco em tempo hábil para que possamos tomar as medidas apropriadas.`,
  t63: `（4） Tratamento de incidentes de segurança da informação`,
  t64: `Apesar das medidas de segurança acima mencionadas, entenda também que não há medidas de segurança abrangentes na rede de informações.`,
  t65: `Se infelizmente ocorrer um incidente de segurança de informações pessoais, iremos informá-lo de acordo com os requisitos das leis e regulamentos: a situação básica e o possível impacto do incidente de segurança, as medidas de descarte que tomamos ou tomaremos, sugestões para você prevenir e reduzir riscos de forma independente e medidas corretivas para você. Iremos informá-lo sobre a situação relevante do incidente por telefone ou notificação. Se for difícil informar o indivíduo em questão da informação um por um, Tomaremos medidas razoáveis e eficazes para publicar um anúncio. Ao mesmo tempo, Também relataremos o tratamento de incidentes de segurança de informações pessoais de acordo com os requisitos regulamentares.`,
  t66: `（5） Validade`,
  t67: `Armazenamos suas informações pessoais apenas em seu país. Tomaremos todas as medidas razoáveis e viáveis para garantir que informações pessoais não relacionadas não sejam coletadas. Reteremos suas informações pessoais apenas pelo menor período exigido por lei para atingir os objetivos declarados nesta política, a menos que uma extensão do período de retenção seja exigida ou permitida por lei.`,
  t68: `Depois que suas informações pessoais excederem o período de retenção, excluiremos suas informações pessoais ou as anonimizaremos de acordo com os requisitos legais.`,
  t69: `5、 Seus direitos`,
  t70: `De acordo com as leis, regulamentos e normas relevantes em seu país, garantimos que você exerça os seguintes direitos sobre suas informações pessoais:`,
  t71: `（1） Acessar suas informações pessoais`,
  t72: `Você tem o direito de acessar suas informações pessoais por meio da central do usuário por conta própria. Consulte (VI) como respondemos à sua solicitação, exceto por exceções especificadas por leis e regulamentos`,
  t73: `（2） Corrigindo suas informações pessoais`,
  t74: `Quando você descobre erros nas informações pessoais que processamos sobre você, você tem o direito de solicitar que façamos correções. Como corrigir informações pessoais pode ser encontrado em (6) Como respondemos à sua solicitação.`,
  t75: `（3） Exclua suas informações pessoais. Nas seguintes situações, você pode solicitar a exclusão de suas informações pessoais:`,
  t76: `1. Nosso manuseio de informações pessoais viola leis e regulamentos;`,
  t77: `2. Coletamos e usamos suas informações pessoais sem o seu consentimento;`,
  t78: `3. Nosso manuseio de informações pessoais viola nosso contrato com você;`,
  t79: `4. Você não usa mais nossos produtos ou serviços, ou cancelou sua conta;`,
  t80: `5. Não forneceremos mais produtos ou serviços.`,
  t81: `Como excluir informações pessoais pode ser encontrado em (VI) Como respondemos à sua solicitação. Se decidirmos atender à sua solicitação, também notificaremos as entidades que obtiveram suas informações pessoais para solicitar a exclusão oportuna, salvo disposição em contrário por leis e regulamentos ou com sua autorização independente.`,
  t82: `Quando você exclui informações de nosso serviço, podemos não excluir imediatamente as informações correspondentes no sistema de backup, mas excluiremos essas informações durante as atualizações de backup.`,
  t83: `（4） Revogue o consentimento e desative a função de uso`,
  t84: `Você pode alterar o escopo de sua autorização para continuarmos coletando informações pessoais ou revogar sua autorização desativando a funcionalidade do dispositivo. Você também pode revogar toda a nossa autorização para continuar coletando suas informações pessoais cancelando sua conta. Como retirar o consentimento e desativar a função de uso pode ser encontrado em (VI) Como respondemos à sua solicitação`,
  t85: `（5） Gerenciamento de recomendações personalizado`,
  t86: `1. Gerenciar recomendações personalizadas de informações de publicidade`,
  t87: `A publicidade personalizada é uma das tecnologias de exibição de publicidade, onde os anunciantes fazem recomendações inteligentes com base em suas preferências dentro do aplicativo. Se você não quiser ser recomendado para publicidade personalizada, poderá escolher se deseja receber recomendações de publicidade personalizada no botão [My] - [System Settings] - [Personalized Advertising Recommendations]. Após o fechamento, a relevância do anúncio que você vê diminuirá.`,
  t88: `2. Gerenciar recomendações de conteúdo personalizadas`,
  t89: `Para garantir que você possa assistir ao conteúdo de seu interesse, oferecemos a capacidade de recomendar conteúdo personalizado. Se você não quiser ser recomendado para conteúdo personalizado, Você pode desativar a recomendação de conteúdo personalizado usando o [My] - [System Settings] - [Personalized Content Recommendation] interruptor. Após o fechamento, a página inicial não exibirá os canais recomendados. Mudar para canais selecionados reduzirá a relevância do conteúdo.`,
  t90: `（6） Cancelamento de conta`,
  t91: "Você pode cancelar sua conta em `My` - `System Settings` - `Cancel Account`. Você pode cancelar sua conta registrada anteriormente a qualquer momento, consulte o 'Aviso de cancelamento' para obter detalhes.",
  t92: `Depois de cancelar sua conta, deixaremos de fornecer produtos ou serviços e excluiremos suas informações pessoais, exceto quando disposto de outra forma por leis e regulamentos.`,
  t93: `Nas seguintes situações, de acordo com os requisitos legais, não poderemos atender à sua solicitação de exclusão de informações pessoais:`,
  t94: `1. Diretamente relacionado à segurança nacional e segurança de defesa nacional;`,
  t95: `2. Diretamente relacionado à segurança pública, saúde pública e grandes interesses públicos;`,
  t96: `3. Diretamente relacionado à investigação criminal, acusação, julgamento e execução de sentenças;`,
  t97: `4. Há evidências suficientes para indicar que você tem malícia subjetiva ou abuso de poder;`,
  t98: `5. Responder à sua solicitação resultará em sérios danos aos seus direitos e interesses legítimos ou de outros indivíduos ou organizações;`,
  t99: `6. Envolvendo segredos comerciais.`,
  t100: `（7） Em resposta ao seu pedido acima`,
  t101: "Você pode optar por desativar as permissões que deseja desativar por meio da função 'Configurações' em seu telefone.",
  t102: "Você também pode fazer uma chamada telefônica clicando em 'Meu' - 'Sobre nós' - 'Entrar em contato com o atendimento ao cliente' e, em seguida, entrar em contato com o atendimento ao cliente para realizar operações do usuário, como consulta de informações pessoais, Correção de informações pessoais, exclusão de informações pessoais, cancelamento da conta do usuário e revogação da autorização acordada. Para garantir a segurança, pode ser necessário fornecer uma solicitação por escrito ou outros meios para provar sua identidade. Podemos primeiro solicitar que você verifique sua identidade antes de processar sua solicitação.",
  t103: 'Forneceremos uma resposta dentro de quinze dias úteis. Se você não estiver satisfeito, também pode apelar entrando em contato com o atendimento ao cliente.',
  t104: `6、 Como lidamos com as informações pessoais das crianças`,
  t105: `Nossos produtos, sites e serviços são voltados principalmente para adultos. Atribuímos grande importância à proteção de informações pessoais de crianças e menores. Se você é menor de 18 anos, antes de usar os serviços relevantes nesta plataforma, você deve ler e concordar com esta política de privacidade sob a supervisão e orientação de seus pais ou outros responsáveis, e usar nossos serviços ou fornecer informações para nós com o consentimento de seus responsáveis.`,
  t106: `Para os casos em que coletamos informações pessoais de menores usando nossos produtos ou serviços com o consentimento de nossos pais ou responsáveis, só usaremos, compartilharemos, transferiremos ou divulgaremos essas informações quando permitido por leis e regulamentos, explicitamente acordado por nossos pais ou responsáveis ou necessário para proteger menores. Se descobrirmos que coletamos informações pessoais de menores sem obter o consentimento verificável dos pais com antecedência, excluiremos as informações relevantes o mais rápido possível.`,
  t107: `7、 Como transferir suas informações pessoais globalmente`,
  t108: `Atualmente, não transmitimos ou armazenamos suas informações pessoais além das fronteiras. Se a transmissão ou armazenamento transfronteiriço for necessário no futuro, informaremos você sobre a finalidade, o destinatário, as medidas de segurança e os riscos de segurança das informações que estão sendo exportadas e obteremos seu consentimento.`,
  t109: `8、 Como atualizar esta política`,
  t110: `Após a atualização desta política de privacidade, esta plataforma exibirá a política de privacidade e o conteúdo atualizados para você na forma de notificações, pop-ups e anúncios do site quando você fizer login e atualizações de versão, para que você possa se manter informado sobre a versão mais recente desta política de privacidade em tempo hábil. Seguiremos rigorosamente as regras de coleta e uso da política de privacidade divulgadas a você na condução de atividades de processamento de informações pessoais. Se a finalidade do uso de informações pessoais mudar, obteremos seu consentimento novamente. Se você continuar a usar nossos serviços, isso significa que você concorda em aceitar o conteúdo revisado desta política.`,
  t111: `9、 Aplicação desta política`,
  t112: `Todos os serviços deste produto estão sujeitos a esta política.`,
  t113: `Quando você fizer login neste produto pela primeira vez, solicitaremos que você leia esta política e obtenha seu consentimento. Salvo acordo em contrário ou exigido por lei, esta política de privacidade não se aplica a produtos ou serviços vinculados a terceiros por ${window.company} Tecnologia e o proprietário registrado dos direitos autorais deste produto. Seu uso desses serviços de terceiros (incluindo quaisquer informações pessoais que você fornecer a esses terceiros) estará sujeito aos termos desses serviços de terceiros e políticas de privacidade (em vez desta política de privacidade). Por favor, leia atentamente os termos desses terceiros para disposições específicas. Proteja suas informações pessoais adequadamente e forneça-as a terceiros apenas quando necessário.`,

  p1: `Esta Política de Privacidade (referida como esta Política) foi emitida e entrou em vigor em 15 de março de 2023 e foi revisada pela última vez em 10 de abril de 2023. Esta Política é um contrato válido entre ${window.region} ${window.company} Technology Co., Ltd. (hereinafter referred to as '${window.company} Technology', '${window.company}' or 'us') e o utilizador (também referido como "você") relativamente a questões relacionadas com a protecção da privacidade do produto cliente Hippo Theater (doravante designado por "este Produto"). Ao clicar para confirmar este contrato ou optar por aceitá-lo de qualquer outra forma, você chegou a um acordo com ${window.company} Technology e ${window.company} e concorda em aceitar todas as seguintes disposições desta política.`,
  p2: `${window.region} ${window.company} Technology Co., Ltd. é o proprietário registrado dos direitos autorais deste produto e goza dos direitos autorais deste produto de acordo com a lei.`,
  p3: `Este produto é operado em conjunto por ${window.company} Tecnologia e ${window.company} para fornecer serviços e desfrutar de direitos, cumprir obrigações e assumir responsabilidades legais por você de acordo com a lei.`,
  p4: `Esta política ajudará você a entender o seguinte conteúdo:`,
  p5: `1. Como coletamos e usamos suas informações pessoais`,
  p6: `2. Como usamos cookies e tecnologias semelhantes`,
  p7: `3. Como compartilhamos, transferimos e divulgamos publicamente suas informações pessoais`,
  p8: `4. Como protegemos suas informações pessoais`,
  p9: `5. Seus direitos`,
  p10: `6. Como lidamos com as informações pessoais das crianças`,
  p11: `7. Como transferir suas informações pessoais globalmente`,
  p12: `8. Como atualizar esta política`,
  p13: `9. Aplicação desta política`,
  p14: `10. Resolução de disputas`,
  p15: `${window.company} A tecnologia e o proprietário registrado dos direitos autorais deste produto atribuem grande importância à proteção de informações pessoais. Quando os usuários (referidos como 'você') usam nossos produtos ou serviços, podemos coletar e usar suas informações relevantes. Uma vez que você opte por usar nossos produtos ou serviços, isso significa que você reconhece e aceita o conteúdo existente destes termos e quaisquer atualizações que possam ser feitas a qualquer momento. Se você não concordar, o serviço de uso deste produto deve ser encerrado. Leia atentamente e compreenda esta Política de Privacidade antes de usar nossos produtos. `,
  p16: `1、 Leia atentamente e compreenda todos os direitos e limitações estipulados neste contrato. Depois de instalar, copiar, baixar, acessar ou usar o produto, isso será considerado como aceitação deste contrato, e você concorda em cumprir os termos deste contrato. Você deve garantir que baixou ou usou este produto de software do site designado e obtê-lo de mídia distribuída legalmente. Caso contrário, não seremos responsáveis por quaisquer riscos e perdas potenciais causados por isso. Lembramos que se você for menor de 18 anos ou não tiver plena capacidade para conduta civil, leia este contrato com seu responsável legal e preste atenção especial aos termos relacionados ao uso deste serviço por menores. Caso contrário, não seremos responsáveis por quaisquer consequências adversas que possam surgir do registro subsequente, uso deste serviço ou recarga de pagamento, exceto conforme expressamente previsto por lei. Temos o direito de rescindir o contrato de usuário entre ambas as partes ao tomar conhecimento  de tais circunstâncias.`,
  p17: `2、 Declaração de Direitos`,
  p18: `1. ${window.region} ${window.company} Technology Co., Ltd. ié o proprietário registrado dos direitos autorais deste software e goza dos direitos autorais deste software de acordo com a lei.`,
  p19: `2. Sem o consentimento por escrito da ${window.company} Technology e do proprietário registrado dos direitos autorais deste software, os usuários não têm permissão para implementar, utilizar, transferir ou licenciar de forma independente qualquer terceiro para implementar, utilizar, transferir os direitos autorais do software acima mencionados e outras propriedades intelectuais para fins lucrativos ou não lucrativos. A ${window.company} Technology e o proprietário registrado dos direitos autorais deste software reservam-se o direito de prosseguir com o comportamento não licenciado mencionado acima.`,
  p20: `3. Proibição de engenharia reversa, compilação reversa e montagem reversa: Os usuários não têm permissão para fazer engenharia reversa, descompilar ou desmontar este produto de software e não têm permissão para modificar nenhum recurso compilado nos arquivos do programa. Exceto conforme expressamente permitido por leis e regulamentos, os usuários devem cumprir as limitações deste contrato.`,
  p21: `4. Segmentação de componentes: Este produto de software é licenciado para uso como um todo e os usuários não têm permissão para usar cada parte separadamente para qualquer finalidade.`,
  p22: `5. Autorização individual: Se forem necessárias vendas comerciais, cópia, distribuição, incluindo, mas não se limitando a, vendas de software, pré-instalação, agrupamento, etc., autorização e permissão separadas por escrito devem ser obtidas do proprietário dos direitos autorais do software.`,
  p23: `6. Direitos reservados: Todos os outros direitos não expressamente autorizados neste contrato permanecerão propriedade da ${window.company} Tecnologia, e os usuários devem obter o consentimento por escrito da ${window.company} Tecnologia ao usar outros direitos.`,
  p24: `7. O conteúdo de vídeo fornecido por este software representa apenas a posição e o ponto de vista do autor e não está relacionado à ${window.company} Tecnologia e ao proprietário registrado dos direitos autorais do software. O autor assume todas as responsabilidades legais.`,
  p25: `3、 Instruções do usuário`,
  p26: `1. Funções do software: pesquise e assista a dramas curtos; Recarregue, baixe e compre dramas curtos; Assista e salve o progresso da visualização.`,
  p27: `2. Em relação ao pagamento de conteúdo digital: Toda a receita gerada pelos pagamentos dos usuários será coletada pela ${window.company} Technology após a dedução das taxas cobradas por instituições de pagamento terceirizadas de acordo com os regulamentos. O conteúdo digital deste software consiste em conteúdo gratuito e conteúdo pago, que são divididos em conteúdo gratuito por tempo limitado e conteúdo gratuito permanente. ${window.company} Technology tem o direito de decidir sobre os padrões e métodos de cobrança dos serviços prestados e fornecer avisos nas páginas de serviço relevantes sobre os padrões de preços. Os usuários têm o direito de escolher se desejam pagar as taxas correspondentes para continuar usando os serviços pagos fornecidos por este software. Se você for menor de 18 anos (especialmente um menor de 14 anos), você deve recarregar com o consentimento de seu responsável legal. Após a recarga neste software, os usuários receberão os pontos de visualização correspondentes, que pode ser usado para comprar os serviços pagos fornecidos por este software. Sua concordância com este contrato será considerada como sua concordância em conceder ${window.company} Tecnologia a autorização para comprar automaticamente o conteúdo do próximo episódio e comprar em massa o conteúdo da série para o seu serviço pago. Uma vez que um usuário recarregue com sucesso (incluindo menores que recarregaram com sucesso com o consentimento de seus responsáveis), ele não deve exigir o reembolso de todo ou parte do valor recarregado por motivos ilegais, exceto em casos de força maior, acordos de acordo ou leis e regulamentos relevantes.`,
  p28: `3. Modificação e atualização do software: O proprietário dos direitos autorais deste software reserva-se o direito de fornecer aos usuários versões modificadas e atualizadas deste software a qualquer momento. Depois que o usuário selecionar e confirmar, o software será atualizado e atualizado, e as taxas de tráfego de dados correspondentes serão geradas usando a rede da operadora, que serão cobradas pela operadora.`,
  p29: `4. Os usuários devem usar este software em conformidade com as leis e este contrato. Os usuários não estão autorizados a se envolver nos seguintes comportamentos, incluindo, mas não se limitando a::`,
  p30: `(1) Exclua ou altere todas as informações eletrônicas de gerenciamento de direitos neste software;`,
  p31: `(2) Evitar ou danificar intencionalmente as medidas técnicas de proteção tomadas pelo proprietário dos direitos autorais para proteger os direitos autorais deste software;`,
  p32: `(3) Usando este software para enganar e enganar outras pessoas;`,
  p33: `(4) Violar os regulamentos nacionais ao excluir, modificar, adicionar ou interferir nas funções dos sistemas de informação de computador, resultando no mau funcionamento dos sistemas de informação de computador;`,
  p34: `(5) Acesso não autorizado a redes de informação informática ou utilização de recursos de rede informática;`,
  p35: `(6) Exclusão, modificação ou adição não autorizada de funções de rede de informações do computador;`,
  p36: `(7) Exclusão, infiltração, modificação ou adição não autorizada de dados e programas aplicativos armazenados, processados ou transmitidos em redes de informações de computadores;`,
  p37: `(8) Interromper a operação normal deste sistema de software ou site, espalhar intencionalmente programas destrutivos, como vírus de computador;`,
  p38: `(9) Qualquer outro comportamento que coloque em risco a segurança da rede de informações do computador;`,
  p39: `(10) Outros comportamentos de uso anormais.`,
  p40: `5. Para este produto de software baixado de sites não designados ou obtido de mídia distribuída ilegalmente, a ${window.company} Tecnologia não pode garantir se o software está infectado com vírus de computador, se há programas de Trojan disfarçados ou software de hacker oculto. O uso de tal software pelos usuários pode levar a riscos imprevisíveis. Recomenda-se que os usuários não baixem, instalem ou usem facilmente. A ${window.company} Technology e o proprietário registrado dos direitos autorais deste software não assumem nenhuma responsabilidade legal decorrente deste.`,
  p41: `6. Proteção de privacidade`,
  p42: `${window.company} A Tecnolopgia promete cumprir a Política de Privacidade (também conhecida como 'Acordo de Privacidade') e as leis aplicáveis, e formulou os quatro princípios de proteção de privacidade a seguir para nos orientar no tratamento de questões relacionadas à privacidade do usuário e às informações em nossos produtos:`,
  p43: `(1) Antes de coletar suas informações, informaremos claramente sobre o método, escopo e finalidade da coleta de informações e obteremos seu consentimento. Usamos as informações que coletamos apenas para fornecer aos usuários produtos e serviços valiosos. Nunca excederemos o escopo ou a frequência de coleta de suas informações pessoais, como contatos, localização, carteira de identidade, informações faciais, etc., e nunca coletará informações que não sejam necessárias ou não tenham cenários de aplicação razoáveis para este serviço de software;`,
  p44: `(2) Desenvolver produtos que estejam em conformidade com os padrões de privacidade e práticas de privacidade. Sem o consentimento do usuário, informações pessoais, como informações de identificação do dispositivo, histórico de navegação do produto, hábitos de uso de pesquisa e listas de aplicativos de software comumente usados, não devem ser compartilhadas ou usadas com outro software aplicativo. Nunca use informações pessoais coletadas, como pesquisas de usuários, histórico de navegação, hábitos de uso, etc. para promoção direcionada ou marketing de precisão sem informar ou indicar de forma proeminente aos usuários, e não fornecer aos usuários a opção de desativar essa função;`,
  p45: `(3) Coleta transparente de informações pessoais;`,
  p46: `(4) Fazer todos os esforços para proteger as informações do usuário às quais temos acesso.`,
  p47: `Com base nisso, você concorda em autorizar a ${window.company} Tecnologia a usar sua foto de perfil de usuário e apelido, para que possamos melhor atendê-lo.`,
  p48: `7. Depois de se tornar um membro VIP, durante o período de associação, você pode assistir a todos os conteúdos de drama curto nesta plataforma.`,
  p49: `8. Durante a instalação e operação deste software, a ${window.company} Technology nunca solicitará permissões não relacionadas ao cenário de uso atual do usuário. Além disso, depois que o usuário recusar explicitamente o pedido de permissão, ele nunca deverá solicitar com frequência a habilitação de permissões não relacionadas ao cenário de serviço atual, como lista de contatos, localização, SMS, gravação, câmera, etc., a fim de forçar o usuário a concordar com a ativação de permissões não relacionadas a este software. Quando os usuários não usam funções ou serviços relevantes, eles não devem solicitar permissão para habilitar contatos, localização, SMS, gravação, câmera, etc. do usuário com antecedência, ou solicitar permissão para habilitar contatos, localização, SMS, gravação, câmera, etc. além das funções ou serviços comerciais deste produto de software. A permissão da área de transferência só é usada em cenários em que o usuário opta por copiar o número de telefone do atendimento ao cliente. Se o usuário não optar por copiar, ${window.company} a tecnologia nunca se aplicará ao usuário para habilitar a permissão da área de transferência.`,
  p50: `9. Os usuários têm o direito de cancelar as informações de sua conta pessoal. Depois que o usuário cancelar as informações da conta pessoal, a tecnologia ${window.company} excluirá as informações da conta pessoal armazenadas pelo usuário neste software (consulte os avisos de cancelamento relevantes para obter detalhes).`,
  p51: `10. Quando você reinstala o sistema móvel ou limpa o software do aplicativo móvel, todas as suas informações de usuário serão perdidas, incluindo, mas não se limitando a: informações de conta pessoal, registros de dramas de pesquisa ou navegação, dramas baixados ou comprados, registros de visualização, progresso de visualização, benefícios de associação e, ao mesmo tempo, todas as visualizações em sua conta pessoal serão redefinidas para zero. Você está ciente de que isso é determinado pelas características do produto, Mas você pode evitar a perda de suas informações de usuário relevantes ao reinstalar o sistema móvel ou limpar aplicativos móveis associando sua conta pessoal a um número de telefone. Quando você não associar sua conta pessoal a um número de telefone celular, em caso de perda de informações do usuário, a ${window.company} Tecnologia e o proprietário dos direitos autorais deste software não serão responsabilizados por isso.`,
  p52: `11. Você não tem permissão para se envolver em atividades ilegais ou se envolver em consumo anormal de qualquer forma nesta plataforma de software ou explorando vulnerabilidades do sistema, defeitos de programa, erros de operação humana, etc. Você não tem permissão para usar software malicioso ou qualquer outro meio para interromper a operação normal desta plataforma de software. Você não tem permissão para se envolver em nenhuma atividade que viole o princípio da boa fé. Caso contrário, uma vez que você seja reconhecido por esta plataforma de software como tendo o comportamento mencionado acima, ${window.company} Technology has the right to directly freeze or cancel your account without prior notice and will not return the account balance. All losses arising from this will be borne by you. In addition, ${window.company} Technology still reserves the right to pursue your legal responsibility in accordance with the law. If your actions cause losses to ${window.company} Technology, you should compensate por todas as perdas causadas à tecnologia ${window.company}. Se você for suspeito de cometer um crime, a ${window.company} Tecnologia tem o direito de transferir as pistas relevantes para o departamento judicial para processamento.`,
  p53: `Atribuímos grande importância à proteção de informações pessoais e direitos e interesses legítimos de menores e promovemos ativamente seu crescimento saudável. Menores (especialmente aqueles com menos de quatorze anos) que usam os serviços sob este contrato sem o acompanhamento e consentimento de seus pais (responsáveis) devem ser prontamente notificados por seus pais (responsáveis) após a descoberta. Ajudaremos seus pais (responsáveis) a lidar com suas contas registradas e contas para garantir que suas responsabilidades e obrigações de tutela sejam cumpridas.`,
  p54: `4、 Proteção dos direitos de propriedade intelectual dos utilizadores e de terceiros`,
  p55: `1. Durante o uso dos serviços incluídos neste software, os usuários podem precisar fornecer conteúdo para a ${window.company} Tecnologia por vários meios, como postar comentários. Todo o conteúdo original publicado pelos usuários neste software é de propriedade do próprio usuário. Os comentários postados pelos usuários representam apenas suas opiniões e não estão relacionados ao proprietário registrado dos direitos autorais deste software ou à tecnologia ${window.company}.`,
  p56: `2. Os usuários não têm permissão para infringir direitos de propriedade intelectual, incluindo os direitos autorais de terceiros, bem como outros direitos. Em caso de disputas legais decorrentes do conteúdo publicado pelo usuário, a responsabilidade será do próprio usuário.`,
  p57: `3. Para qualquer conteúdo publicado pelo usuário neste software, o usuário concorda em autorizar a ${window.company} Tecnologia a ter um direito gratuito, permanente e não exclusivo de usar todos os direitos autorais em todo o mundo. O usuário licencia a tecnologia ${window.company} para tomar medidas legais separadas contra qualquer violação por qualquer parte (incluindo, mas não se limitando a, litígios, relatórios, emissão de cartas de advogados, etc.).`,
  p58: `4. Se os comentários do usuário infringirem os direitos autorais ou outros direitos de terceiros ou violarem disposições legais, depois que o terceiro levantar objeções ou for descoberto por meio do processo de revisão de conteúdo, a ${window.company} Technology tem o direito de excluir o conteúdo relevante e tem o direito de perseguir ou ajudar o terceiro a buscar a responsabilidade legal do usuário.`,
  p59: `5. Sem permissão por escrito da ${window.company} Tecnologia, qualquer entidade está proibida de usar qualquer programa de rastreamento, programa spider, programa antropomórfico, tecnologia de infiltração, programa hacker e outros dispositivos automáticos ou programas manuais para invadir, ler, copiar, armazenar este software ou qualquer conteúdo nele contido, ou se envolver em atividades ilegais ou desonestas. Caso contrário, a ${window.company} Technology tem o direito de congelar ou cancelar diretamente sua conta sem notificá-lo e tem o direito de não devolver o saldo da conta. Todas as perdas incorridas como resultado serão suportadas unilateralmente por você. Além disso, ${window.company} A tecnologia ainda tem o direito de responsabilizá-lo legalmente para compensar todas as perdas causadas à tecnologia ${window.company} devido às suas ações mencionadas acima, a fim de proteger efetivamente os direitos legítimos da tecnologia ${window.company}.`,
  p60: `5、 Declaração de direitos autorais do conteúdo`,
  p61: `${window.company} Tecnologia promete cumprir e respeitar a proteção dos proprietários de direitos autorais de acordo com as leis, regulamentos e outros documentos normativos chineses atuais. Como detentor dos direitos, quando você descobrir que o conteúdo fornecido infringe seus direitos e interesses legítimos, você deve primeiro nos enviar um 'aviso de direitos' de acordo com o processo de processamento cs{'@'}ishugui.com Ou ligue para o atendimento ao cliente em 400-118-0066. Tomaremos medidas para remover conteúdo relevante ou desconectar ou bloquear links relacionados de acordo com as leis e regulamentos chineses e documentos normativos governamentais. Depois de tomar as medidas acima mencionadas, você não deve nos responsabilizar e ao proprietário registrado dos direitos autorais deste software por quaisquer responsabilidades legais.`,
  p62: `6、 Isenção de responsabilidade e limitação de responsabilidade`,
  p63: `1. O usuário confirma que está ciente de todas as funções deste software e das operações necessárias para implementar cada função deste software. Eles optam voluntariamente por usar este software e serviços relacionados de acordo com suas próprias necessidades. Os riscos e todas as consequências do uso deste software e serviços relacionados serão totalmente suportados por você de acordo com a lei. O proprietário registrado dos direitos autorais deste software e da tecnologia ${window.company} não será responsabilizado.`,
  p64: `2. Este software passou por testes detalhados, mas não podemos garantir total compatibilidade com todos os sistemas de software e hardware, nem podemos garantir que este software esteja completamente livre de erros. Se houver incompatibilidades e erros de software, os usuários podem ligar para a linha direta de atendimento ao cliente 400-118-0066 para relatar a situação e obter suporte técnico. Se os problemas de compatibilidade não puderem ser resolvidos, os usuários podem excluir este software.`,
  p65: `3. Na extensão máxima permitida pela lei aplicável, o proprietário registrado dos direitos autorais deste software e da ${window.company} Technology não será responsável por quaisquer danos e riscos decorrentes do seu uso ou incapacidade de usar este software, incluindo, mas não se limitando a, danos pessoais diretos ou indiretos, perda de lucros comerciais, interrupção comercial, perda de informações comerciais,  ou quaisquer outras perdas econômicas.`,
  p66: `4. O proprietário registrado dos direitos autorais deste software e da tecnologia ${window.company} não se responsabiliza por qualquer perda causada por danos ou perda de informações, problemas no sistema de telefonia móvel ou quaisquer outros motivos de força maior devido a falha no sistema de telecomunicações ou na rede da Internet, falha no telefone celular ou operação do programa de vírus.`,
  p67: `5. Se o usuário violar as disposições deste contrato e causar danos ao proprietário registrado dos direitos autorais deste software e da ${window.company} Technology, a ${window.company} Technology tem o direito de tomar medidas, incluindo, mas não se limitando a, interromper a licença de uso do usuário, interromper a prestação de serviços, restringir o uso e buscar responsabilidades legais.`,
  p68: `7、 Jurídico e Resolução de Disputas`,
  p69: `1. Este contrato é regido pelas leis do seu país.`,
  p70: `2. Quaisquer disputas decorrentes ou relacionadas a este contrato serão resolvidas por meio de consulta amigável entre as partes; Se a negociação falhar, qualquer uma das partes tem o direito de submeter a disputa à comissão de arbitragem para arbitragem.`,
  p71: `8、 Outros termos`,
  p72: `1. ISe qualquer disposição deste Contrato for total ou parcialmente inválida ou inexequível por qualquer motivo, ou violar qualquer lei aplicável, tal disposição será considerada excluída, mas as demais disposições deste Contrato permanecerão válidas e vinculativas.`,
  p73: `2. ${window.company} Tecnologia tem o direito de modificar este Contrato a qualquer momento, de acordo com as mudanças nas leis e regulamentos relevantes, bem como ajustes nas condições e estratégias operacionais da Empresa. O acordo revisado será publicado no site e anexado à nova versão do software. Quando surgirem disputas, o texto mais recente do contrato prevalecerá. Se o usuário discordar das alterações, ele mesmo poderá excluir o software. Se o usuário continuar a usar este software, será considerado que você aceitou as alterações a este contrato.`,
  p74: `3. ${window.company} Tecnologia tem o direito de interpretar e modificar este Contrato na extensão máxima permitida por lei.`,

  login_first: `Por favor, faça o login`,
  sessionTimeout: `Por favor, faça login novamente`,
  unknown_err: `Erro desconhecido`,
  way: `Metodo`,

  starCardPlan: `Cartão M`,
  starCardPrivate: `Benefícios do Cartão M`,
  starCardIntroduction: `O M Card é a prova de direitos para o sistema de promoção M-Plan. O M Card é dividido em M-Card e M Card Pro. Tornar-se usuário do M Card pode ganhar recompensas.`,

  banQuanZhongChou: `Direitos autorais`,
  banQuan1: `Rendimento de direitos autorais de 130% e mais recompensas.`,
  banQuan2: `Rendimento de direitos autorais de 150% e mais recompensas.`,

  enterPhoneNumber: `Por favor, digite seu número de telefone`,
  enterRightNumber: `Digite o número de telefone correto`,
  pwdNotNull: `O código de verificação não pode estar vazio`,
  checkAgreement: `Por favor, concorde com a política de privacidade e o contrato do usuário primeiro`,
  second: `Segundo`,
  sendSuccess: `Envio bem-sucedido`,
  codeValid: `Obter código`,

  promoteCount: `Número de M Equipe`,
  today: `Hoje`,
  promoteList: `Lista de Promoções`,
  nickName: `Apelido`,
  level: `Nível`,
  register: `Registro`,
  noMore: `Não há mais conteúdo`,
  page: `Página`,
  drawout: `Retirar`,
  logining: `Registando`,
  shortMoive: `Esquetes`,
  otherLogin: `Faça login com outros métodos`,

  uploading: `Enviando`,
  timeout: `Verifique a conexão de rede`,
  uploadLimitOne: `Você só pode fazer upload de uma imagem`,
  unit: window.currency,

  drawoutNotice: `<p>1.Preencha as informações reais da conta. O usuário deve arcar com a perda causada por informações incorretas da conta.</p>
  <p>2.A retirada requer revisão. A retirada pode ser adiada por um tempo.</p>
  <p>3.Valor mínimo de retirada ${window.currency}$extractSingleMin.</p>
  <p>4.Valor máximo de retirada ${window.currency}$extractSingleMax.</p>
  <p>5.A taxa de retirada $extractChargeRate, será deduzida do valor da retirada.</p>
  <p>6.Você pode solicitar saques $extractDailyCount por dia.</p>
  <p>7.A retirada será recebida dentro de um dia.</p>`,

  submitItem: `Submeter`,
  caresure: `Ponta`,
  server_bank: `Banco`,
  server_bankCardNo: `Número da Conta`,
  server_bankCardHolder: `Nome da conta`,
  server_email: `E-mail`,
  server_phone: `Celular`,

  pleaseInputPwd: `Por favor, digite a senha`,
  useCodeLogin: `Faça login com código de verificação`,
  usePwdLogin: `Login com senha`,
  pwdLimitTip: `A senha deve ter de 6 a 20 caracteres`,
  pleaEnterInviteCode: `Por favor, insira o código de convite`,
  setPwd: `Definir senha de login`,
  pwdPlaceholder: `Confirme o comprimento da senha de 6 a 20 caracteres`,
  optSuccess: `Bem-sucedido`,

  Balance: `Saldo`,
  WithdrawAmountTip: `O valor do saque não pode estar vazio`,
  WithdrawAmountTipMin: `O saldo da sua carteira é menor que o valor mínimo de saque`,
  WithdrawAmountTipDecimal: `O valor da retirada tem duas casas decimais precisas`,
  TransferAmountTip: `O valor do transfer não pode estar vazio`,
  TransferAmountTipMin: `O saldo da sua carteira é menor que o valor mínimo de transferência`,
  TransferAmountTipDecimal: `O valor da transferência tem duas casas decimais precisas`,
  Submit: `Submeter`,
  BankOfDeposit: `Depósito do Banco`,
  BankOfDepositTip: `O nome do banco não pode estar vazio`,
  BankCardNumber: `Número do cartão bancário`,
  BankCardNumberTip: `O número do cartão bancário não pode estar vazio`,
  userName: `Nome de usuário`,
  userNameTip: `O nome de usuário não pode estar vazio`,

  TransferUserID: `Identificação de usuário`,
  TransferUserIDTip: `A identificação do usuário não pode estar vazia`,
  ReTransferUserID: `Confirmar identificação do usuário`,
  ReTransferUserIDTip: `A identificação do usuário não pode estar vazia`,
  TransferUserIDTips: `A identificação do usuário inserido duas vezes é inconsistente`,

  VerificationCode: `Código de verificação`,
  VerificationCodeTip: `O código de verificação não pode estar vazio`,
  GetVerificationCode: `Obter código`,

  Precautions: `Ponta`,
  PrecautionsTip1: `1.Por favor, preencha as informações reais da conta. O usuário deve arcar com a perda causada por informações incorretas da conta.`,
  PrecautionsTip2: `2.A retirada requer revisão. A retirada pode ser adiada por um tempo.`,
  PrecautionsTip3: `3.Valor mínimo de retirada 1.`,
  PrecautionsTip4: `4.Valor máximo de retirada 1.`,
  PrecautionsTip5: `5.Taxa de retirada 1, será deduzida do valor da retirada.`,
  PrecautionsTip6: `6.Você pode solicitar 1 saque por dia.`,
  PrecautionsTip7: `7.A retirada será recebida dentro de um dia.`,

  confirmPay: `Confirme o pagamento $y`,
  goPay: `Pague $y agora`,
  couponState: `Incompatibilidade de tipo`,
  couponNo: `Código de resgate não disponível`,
  coupinErr: `O código de resgate está incorreto`,
  couponUse: `Desconto $y`,
  BalanceDeductions: `O saldo da carteira paga $y`,
  ExchangeStarCard: `Resgatar cartão M`,
  EnterMembershipTips: `Resgate o M-Card ou o M Card Pro por alguns dias`,
  EnterMembership: `Resgate $x por $y dias`,
  ExchangeNotUsed: `O código de resgate não foi usado`,
  ExchangeUsed: `O código de resgate foi usado`,
  noExchange: `O código de resgate não existe`,
  noRedemptionTips: `Insira o código de resgate`,
  PleaseAmount: `Por favor, selecione o valor da participação:`,
  PaymentAgreement: `O pagamento é considerado como seu acordo`,
  DramaAgreement: `Acordo de Serviço Drama Rich Star Card`,
  setPayment: `Definir Método de Recebimento`,
  saveInfo: `Salvar Informações`,
  myBuy: `Comprar`,
  mySell: `Vender`,
  placeOrder: `Fazer Pedido`,
  whole: `Todos`,
  myOrder: `Meus Pedidos`,
  anonymousUser: `Usuário Anônimo`,
  deal: `Negócio`,
  sell: `Vender`,
  integral: `Pontos`,
  demand: `Demanda`,
  minute: `Minuto`,
  sellOut: `Vender Tudo`,
  purchase: `Comprar`,
  pendingOrders: `Pedidos Pendentes`,
  confirmOrder: `Confirmar Pedido`,
  choosePaymentMethod: `Escolher Método de Pagamento`,
  back: `Voltar`,
  orderGenerated: `Pedido Gerado`,
  please: `Por Favor`,
  paymentInformation: `Confirmar Informações de Pagamento do Comprador`,
  reportExceptions: `Reportar Exceções`,
  sellerToConfirm: `Aguardando Confirmação de Pagamento do Vendedor`,
  ordersCannotStage: `Pedido Não Pode Ser Cancelado Nesta Etapa`,
  orderCompleted: `Pedido Concluído`,
  contactustomer: `Se houver objeções, por favor, entre em contato com o serviço de atendimento ao cliente para mediação`,
  customerServiceMediation: `Mediação do Atendimento ao Cliente`,
  confirmCompletion: `Confirmar Conclusão`,
  PleaseWaitPayment: `Aguarde o Pagamento do Comprador`,
  reportCorrect: `Reportar Exceção do Pedido, Está Correto?`,
  addMethod: `Adicionar Método de Pagamento`,
  selectType: `Selecionar Tipo`,
  sellPoints: `Vender Pontos`,
  sellUsers: `Vender Pontos da Conta para Outros Usuários`,
  purchasePoints: `Comprar Pontos`,
  canPointsUsers: `Pode Comprar Pontos de Outros Usuários`,
  messageBoard: `Quadro de Mensagens`,
  you: `Você`,
  reply: `Responder`,
  enterBelow: `Por favor, insira as informações da resposta abaixo`,
  viewDetails: `Ver Detalhes`,
  paymentMethod: `Método de Pagamento`,
  addPaymentInformation: `Adicionar Informações de Pagamento`,
  paymentDeadline: `Prazo para Pagamento`,
  totalAmount: `Valor Total`,
  price: `Preço Unitário`,
  tradingPrecautions: `Precauções de Comércio`,
  afterQuantity: `1. Após confirmar o pedido, seus pontos serão bloqueados de acordo com a quantidade do pedido.`,
  confirmCancelled: `2. Por favor, confirme a conclusão da transação dentro do prazo especificado, caso contrário, ela será cancelada automaticamente.`,
  cancellationOrder: `Cancelar Pedido`,
  CancelOrder: `Cancelar Listagem`,
  transactionDetails: `Detalhes da Transação`,
  myPendingOrder: `Meu Pedido Pendentes`,
  orderType: `Tipo de Pedido`,
  myTransaction: `Minha Transação`,
  orderStatus: `Status do Pedido`,
  buyPoints: `Pontos de Compra`,
  release: `Liberar`,
  locking: `Bloqueio`,
  end: `Encerrar`,
  removeFromShelves: `Remover das Prateleiras`,
  establish: `Criar`,
  approachingTimeout: `Aproximando-se do Tempo Limite`,
  overtime: `Tempo Esgotado`,
  buyerConfirmed: `Comprador Confirmado`,
  sellerConfirmed: `Vendedor Confirmado`,
  successfulTrade: `Negócio Bem-Sucedido`,
  createOrder: `Criar Pedido de Compra`,
  createSellOrder: `Criar Pedido de Venda`,
  enterPurchaseQuantity: `Inserir Quantidade de Compra`,
  enterSale: `Inserir Quantidade de Venda`,
  pointsBalance: `Saldo de Pontos`,
  setMaxQuantity: `Definir Quantidade Máxima`,
  setPurchasePrice: `Definir Preço de Compra (Preço Total)`,
  setSalePrice: `Definir Preço de Venda (Preço Total)`,
  rangePrice: `Faixa de Preço por Ponto`,
  confirmPeddingOrder: `Confirmar Listagem`,
  pointsRange: `Preço por Ponto Fora da Faixa`,
  orderSuccessfully: `Pedido Realizado com Sucesso`,
  OrderErr: `Erro no Pedido`,
  OrderOverTime: `Pedido Expirado`,
  CustomeRservice: `Em caso de dúvidas, entre em contato com o atendimento ao cliente para mediação`,
  UpdataPedding: `Modificar Pedido Pendente`,
  CustomerService: `Mediação do Atendimento ao Cliente`,
  WaitingPayment: `Aguardando Pagamento`,
  Collectionpayment: `Aguardando Cobrança`,
  ConfirmCompletion: `Confirmar Conclusão`,
  ContactCustomer: `Contate o Atendimento ao Cliente para Mediação`,
  NumberPending: `Número de Pedidos Pendentes Hoje`,
  Edit: `Editar`,
  Create: `Criar`,
  Bill: `Conta`,
  SellBill: `Vender Conta`,
  PendingOrders: `Pedidos Pendentes`,
  Reason: `motivo`,
  Kong: `vazio`,
  USDTAmout: `Após sacar para USDT, o valor é $slot`,
  enterPassword: `Por favor, insira a senha`,
  enterSecondPassword: `Por favor, insira a senha de confirmação`,
  confirmPassword: `As duas senhas não coincidem`,
  registerIn: `Registre-se e faça login agora`,
  LoginCode: `Login com código de verificação`,
  LoginPassword: `Login com senha`,
  goLogin: `Ir para login`,
  goRegister: `Ir para registro`,

  WALLET: 'Carteira',
  THIRD_PAY: 'Terceiro Pagamento',
  Using: `Usando`,
  SwitchAccount: `Trocar Conta`,
  AddAccount: `Adicionar Conta`,
  Finish: `Concluir`,
  ClearAccount: `Tem certeza de que deseja limpar sua conta?`,

  // newHome
  AppDesc: `Plataforma Inovadora de Compartilhamento de Vídeos Curtos`,
  AppIntroduce: `MovShot é uma plataforma de ponta que combina entretenimento e oportunidades de ganho para usuários que gostam de assistir a dramas curtos. A plataforma permite que os usuários ganhem dinheiro ao interagir com dramas curtos por meio de vários recursos interativos, como assistir, comentar e compartilhar.`,
  DownloadNow: `Baixar Agora`,
  SignIn: `Entrar`,
  MarketBackground: `CONTEXTO DE MERCADO`,
  MarketDesc: `Com mais de 1 bilhão de usuários de vídeos curtos globalmente, 50,4% assistiram a micro-dramas com menos de 3 minutos de duração. As previsões da indústria sugerem que, até 2023, o tamanho do mercado de dramas curtos pode alcançar entre $2,8 bilhões e $4,2 bilhões, com crescimento contínuo esperado até 2024.`,
  ProductFeatures: `CARACTERÍSTICAS DO PRODUTO`,
  AppFeature1: `1. Conteúdo Diversificado: `,
  AppFeatureDesc1: `MovShot oferece uma rica biblioteca de dramas curtos, cobrindo gêneros como comédia, romance, suspense e mais, atendendo a uma ampla gama de preferências dos usuários.`,
  AppFeature2: `2. Modelo de Ganhos: `,
  AppFeatureDesc2: `MovShot permite que os usuários ganhem dinheiro real assistindo, comentando e compartilhando dramas curtos. Este modelo de monetização exclusivo o torna altamente atraente para os usuários.`,
  AppFeature3: `3. Comunidade Interativa: `,
  AppFeatureDesc3: `MovShot promove um forte senso de comunidade ao permitir que os usuários curtam, comentem e compartilhem conteúdo, incentivando a interação ativa entre os usuários.`,
  AppFeature4: `4. Recomendações Personalizadas: `,
  AppFeatureDesc4: `Usando algoritmos inteligentes, MovShot fornece recomendações de conteúdo personalizadas com base nos interesses dos usuários, aumentando o engajamento e a satisfação.`,
  CompetitiveAdvantages: `VANTAGENS COMPETITIVAS`,
  CompetitiveAdvantages1: `1. Promoção e Distribuição com Licenciamento Aberto: `,
  CompetitiveAdvantages1_1: `MovShot aproveita licenças abertas, permitindo que conteúdos não explorados sejam promovidos na plataforma e realizem seu pleno potencial comercial.`,
  CompetitiveAdvantages2: `2. Dramas Curtos de Alta Qualidade Produzidos Internamente: `,
  CompetitiveAdvantages2_1: `MovShot investe na criação de dramas curtos originais de alta qualidade, enriquecendo sua biblioteca de conteúdo e aumentando a retenção de usuários.`,
  CompetitiveAdvantages3: `3. Gestão de IP de Atores de Micro-Dramas: `,
  CompetitiveAdvantages3_1: `Ao desenvolver IP para atores de micro-dramas, MovShot aproveita a influência dos atores para aumentar a visibilidade da plataforma, impulsionando o engajamento dos usuários e a conversão de tráfego.`,
  CardTitle1: `Layout da Cadeia da Indústria`,
  CardDesc1: `MovShot integra o processo de produção e distribuição de conteúdo, com proprietários de direitos autorais, distribuidores e agentes de streaming trabalhando juntos para garantir um fluxo suave de conteúdo de upstream para downstream.`,
  CardTitle2: `Sistema de Cartão M`,
  CardDesc2: `O Cartão M é uma credencial para usuários participarem do programa de ganhos do MovShot Pro. Disponível em dois tipos, Cartão M de Teste e Cartão M Premium, ambos oferecem acesso a recompensas em dinheiro e outros benefícios.`,
  CardTitle3: `Investimento em Direitos Autorais`,
  CardDesc3: `MovShot oferece uma oportunidade de investimento única onde os usuários podem investir na promoção de dramas com direitos autorais, ganhando uma parte dos lucros resultantes. Os retornos flutuam com base no ciclo de promoção de cada drama.`,
  CardTitle4: `Ganho Social e Baseado em Equipe`,
  CardDesc4: `MovShot introduz um sistema de ganho social e baseado em equipe, permitindo que os usuários formem equipes e usem suas redes para maximizar os ganhos. Promoções baseadas em equipe desbloqueiam recompensas e bônus adicionais, aumentando o apelo da plataforma.`,
  UserFeedback: `FEEDBACK DOS USUÁRIOS`,
  UserFeadbackDesc: `MovShot recebeu feedback positivo de seus usuários!`,
  Career1: `Estudante`,
  Career2: `Professor`,
  Career3: `Programador`,
  Career4: `Motorista`,
  UserTalk1: `"Posso ganhar dinheiro enquanto me divirto—é incrível!"`,
  UserTalk2: `· "Os dramas curtos são ricos e envolventes, e o modelo de ganhos é muito atraente."`,
  UserTalk3: `"As recomendações personalizadas do MovShot sempre me ajudam a encontrar dramas que amo."`,
  UserTalk4: `·"A comunidade interativa me ajuda a fazer novos amigos."`,
  Platfrom: `Visão da Plataforma`,
  PlatformDesc: `MovShot visa continuar aprimorando seu conteúdo, experiência do usuário e base de usuários, enquanto explora novas estratégias de monetização para um crescimento sustentável. Ao se manter atento às tendências do mercado e às necessidades dos usuários, o MovShot está preparado para um sucesso contínuo no mercado em evolução dos dramas curtos.`,
  Movshot: `Movshot`,
  Contact: `Contato`,

  ContactDesc: `We're a friendly team that would love to hear your ideas, feedback and questions.`,
  Message: `Message...`,
  EmailErr: `Email format error`,
  FormErr: `Please fill out the complete form`,
  FormDesc: `Enter at least 10 characters`,
  noPaymentChannel: `Sem canais de pagamento`,
  pleaSelectCoinType: `Seleccione por favor o tipo de moeda`,
  overDelete: `Cancelado`,
  InputInvite: `Por favor, insira o código de convite (opcional)`,
  registerSuccess: `Registro bem-sucedido`,
  WebVersion: `versão web`,
};
